<template>
  <v-card style="min-height: 85vh">
    <v-card-title class="mb-3">
      <span class="text-h6">{{ $t("labels.config") }}</span>
      <v-spacer></v-spacer>

      <v-btn small color="error" outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-row>
        <v-col cols="12" md="6">
          <WarningConfigWarehouse />
        </v-col>
        <v-col cols="12" md="6">
          <WarningConfigTable />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    WarningConfigTable: () =>
      import("@/components/employee/WarningConfigTable"),
    WarningConfigWarehouse: () =>
      import("@/components/employee/WarningConfigWarehouse"),
  },
  name: "WarningConfigDialog",
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
